import { ArrowUpRightIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import LinkTo from 'components/linkTo';
import StrapiRichTextComponent from 'strapi/components/StrapiRichText';
import { StrapiAnnouncementBarData } from 'strapi/types/utils';

import styles from './AnnouncementBar.module.scss';

export const AnnouncementBar = ({
  message,
  cta_link,
  cta_text,
}: StrapiAnnouncementBarData) => (
  <section
    className={classNames(
      'd-flex f-wrap bg-purple-100 p16 ai-center jc-center p-p',
      styles.message
    )}
  >
    {message && (
      <StrapiRichTextComponent richText={message} paragraphClassName="p-p" />
    )}
    {cta_link && (
      <LinkTo
        href={cta_link.url}
        target={cta_link.target}
        className="p-a fw-bold tc-grey-900 ml8 d-flex"
      >
        {cta_text || 'Click here'}
        <ArrowUpRightIcon size={16} className="ml8" />
      </LinkTo>
    )}
  </section>
);
