import { useTranslation } from '@getpopsure/i18n-react';
import { Badge } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { reviewsBySite } from 'constants/reviews';
import { AggregateRating } from 'next-seo/lib/types';
import React from 'react';

import GoogleReviewBadge from './googleReviewBadge';
import styles from './style.module.scss';
import TrustpilotReviewBadge from './trustpilotReviewBadge';

interface ReviewBadgeProps {
  className?: string;
  displayReviews?: boolean;
  displayReviewCount?: boolean;
}

export const featherAggregatedRating: AggregateRating = {
  ratingValue: `${
    (reviewsBySite.trustpilot.rating + reviewsBySite.google.rating) / 2
  }`,
  ratingCount: `${
    reviewsBySite.trustpilot.numberOfReviews +
    reviewsBySite.google.numberOfReviews
  }`,
  bestRating: '5',
};

const ReviewBadge = ({
  className,
  displayReviews,
  displayReviewCount,
}: ReviewBadgeProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        styles.container,
        'd-flex f-wrap ai-center gap24',
        className
      )}
    >
      <div className={styles.reviewRating}>
        <GoogleReviewBadge displayReviews={displayReviews} />
      </div>
      <TrustpilotReviewBadge displayReviews={displayReviews} />
      {displayReviewCount && (
        <Badge
          variant="neutral200"
          size="small"
          className={classNames(styles.reviewCount)}
        >
          {t('component.reviews.reviewCount.label', 'Based on 1,700+ reviews')}
        </Badge>
      )}
    </div>
  );
};

export default ReviewBadge;
