import type { Attribute } from '@strapi/strapi';
import {
  STRAPI_DEINDEX_TAG,
  STRAPI_EXCLUDE_FROM_SITEMAP_TAG,
} from 'constants/strapi';

export const isPageExcludedFromSiteMap = (
  tagsList: Attribute.JsonValue | undefined
) => {
  if (tagsList && Array.isArray(tagsList)) {
    return tagsList.includes(STRAPI_EXCLUDE_FROM_SITEMAP_TAG);
  }

  return false;
};

export const isPageMarkedForDeindex = (
  tagsList: Attribute.JsonValue | undefined
) => {
  if (tagsList && Array.isArray(tagsList)) {
    return tagsList.includes(STRAPI_DEINDEX_TAG);
  }

  return false;
};
