import { captureException } from '@sentry/nextjs';
import { isProduction } from 'util/env';

export const MissingRequiredFields = () => {
  if (isProduction) {
    const errorMessage = '[Prismic] Required field(s) missing';
    captureException(new Error(errorMessage));
    throw new Error(errorMessage);
  }
  return (
    <div className="p-body p-notice--danger p-h3">
      You are seeing this message because required field is missing in this
      slice. Please go back to Prismic editor and check again.
    </div>
  );
};
