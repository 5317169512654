import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import type { RootNode } from '@strapi/blocks-react-renderer/dist/BlocksRenderer';
import CtaLink from 'components/CtaLink';
import ModalLink from 'components/ModalLink';
import React from 'react';

import styles from './StrapiRichText.module.scss';

export const StrapiRichTextComponent = ({
  richText,
  paragraphClassName,
}: {
  richText: RootNode[];
  paragraphClassName?: string;
}) => {
  return (
    <BlocksRenderer
      content={richText}
      blocks={{
        // You can use the default components to set class names...
        paragraph: ({ children }) => (
          <p className={paragraphClassName ?? 'p-p mt8'}>{children}</p>
        ),
        // ...or point to a design system
        heading: ({ children, level }) => {
          switch (level) {
            case 1:
              return <h1 className="p-h1">{children}</h1>;
            case 2:
              return <h2 className="p-h2">{children}</h2>;
            case 3:
              return <h3 className="p-h3">{children}</h3>;
            default:
              throw Error(`Unsupported heading level ${level}`);
          }
        },
        // For links, you may want to use the component from your router or framework
        link: ({ url, children }) => {
          const modalLinkPrefix = '/#modal--';
          if (url.startsWith(modalLinkPrefix)) {
            const uid = url.substring(modalLinkPrefix.length);
            if (uid === '') throw Error('Modal uid is not defined');
            return <ModalLink uid={uid}>{children}</ModalLink>;
          }
          return (
            <CtaLink className="p-a fw-bold" href={url}>
              {/* RichText serializes children as string */}
              {children as unknown as string}
            </CtaLink>
          );
        },
        list: ({ children, format }) => {
          return format === 'ordered' ? (
            <ol className={`p-p mt8 ${styles.olist}`}>{children}</ol>
          ) : (
            <ul className={`p-p mt8 ${styles.ulist}`}>{children}</ul>
          );
        },
        code: () => {
          throw Error('Code node was not implemented');
        },
        quote: () => {
          throw Error('Quote node was not implemented');
        },
      }}
      modifiers={{
        bold: ({ children }) => <span className="fw-bold">{children}</span>,
        italic: ({ children }) => <span className="fs-italic">{children}</span>,
      }}
    />
  );
};
